import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../CustomCss/CustomStyle.css";
import CrossPattern from "../Images/Nau/CrossPattern.jpg";

import SEO from "../Components/Seo/Seo";
import { HelmetProvider } from "react-helmet-async";
import PrivacyBanner from "../Images/inner-page-banner.png";
import signatureM from "../Images/Signature.jpg";
export default function PrivacyPolicy(index) {
 
  return (
    <>
      <HelmetProvider>
        <SEO
          description="Orion Marine Concepts is a top class approved system for managing planned, unplanned, condition and counter based maintenance with a user-friendly interface making it swift and practical for the operators."
          title="Orion Marine Concepts | Planned Maintenance System"
          keywords="Maritime ERP, Ship Management Software, Maritime Compliance, Fleet Management, Business Intelligence, Green Shipping SolutionsMaritime ERP, Cloud-Based Software, Safety Management, Crew Optimization, Fleet Performance, Certificate Management, Risk Assessment, Centralized Filing System, Third-Party Integration, NAU 2.1. Marine Software solutions, Electronic Marpol Seal Log Marine, Waste Management Systems, Vdr Analysis and Assesment Software, Maritime Software, Maritime Software Solutions, Maritime Software Companies"
        />
      </HelmetProvider>

      <Row className="m-l-0 m-r-0 p-l-0 p-r-0">
        <Container fluid>
          <Row className="text-start">
            <Row
              className=" pt-5 pb-5 text-center"
              style={{
                backgroundImage: "url(" + PrivacyBanner + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "0",
                margin: "0",
                height: "290px",
              }}
            >
              <Col xs={12} md={2}></Col>
              <Col xs={12} md={8}>
                <h1
                  className="BiEmpowering"
                  style={{
                    color: "#055160",
                    paddingTop: "70px",
                    textAlign: "center",
                  }}
                >
                  Privacy Policy
                </h1>
                <h3
                  
                  style={{
                    color: "#055160",
                    paddingTop: "10px",
                    textAlign: "center",
                  }}
                >
                ISO 9001:2015 and ISO 27001:2022 certified
                </h3>
              </Col>
              <Col xs={12} md={2}></Col>
            </Row>
          </Row>
        </Container>
        <Container>
          <Row className="text-start">
            <Col xs={12} md={2}></Col>

            <Col xs={12} md={8}>
             
                <h4 className="mt-5">
                  Your privacy is very important to Orion Group ("ORION"). We
                  want you to use our web applications and Services (as defined
                  in the&nbsp;<strong>Terms of Service</strong>) with complete
                  confidence. Accordingly, we have developed this&nbsp;
                  <strong>Privacy Policy</strong>&nbsp;to explain ORION's
                  policies with respect to the use, storage and disclosure of
                  personally-identifiable information ("Personal Data") and
                  other data collected through the web applications.
                </h4>
                <h5 className="mt-3 mb-3">
                  <strong>
                    ORION MAINTAINS AN INFORMATION SECURITY PROGRAM WHICH
                    REQUIRES PRACTICES TO PROTECT THE SECURITY OF PERSONAL DATA
                    THAT WE COLLECT AGAINST ACCIDENTAL OR UNLAWFUL DESTRUCTION,
                    LOSS, ALTERATION AND UNAUTHORIZED DISCLOSURE OR ACCESS.
                    PERSONAL DATA IS ANY INFORMATION RELATING TO OR IDENTIFIED
                    OR IDENTIFIABLE NATURAL PERSON WHO CAN BE IDENTIED DIRECTLY
                    OR INDIRECTLY BY REFERENCE TO THE INFORMATION. THIS
                    DEFINITION IS INTENDED TO COMPLY WITH APPLICABLE PRIVACY AND
                    DATA PROTECTION LAWS INCLUDING THE GENERAL DATA PROTECTION
                    REGULATIONS OF THE EUROPEAN UNION ("GDPR").
                  </strong>
                </h5>
                <p>
                  By using this web applications, you consent to the terms of
                  this&nbsp;<strong>Privacy Policy.</strong>
                </p>
                <h2 className="mt-5 mb-5">
                  <u className="fs-25">Information we Collect :</u>
                </h2>
                <Accordion alwaysOpen className="text-start">
                  <Row className="CommonAccordion m-t-40">
                    <Accordion.Item eventKey="0" className=" mt-2 mb-2">
                      <Accordion.Header>
                        Personal Data That You Provide to the Web applications
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            We do not require or ask the users for any provide
                            Personal Data for our web applications. All our
                            system access and identifications procedures are
                            limited to use of subscribed Company official
                            business email addresses shared by the Company PIC
                            for logging and maintaining session history. We use
                            this information to provide notifications at the
                            user requests and operate our web applications
                            (including by customizing certain features for more
                            personalized use) and to contact as necessary
                            through official email.
                          </li>
                          <li>
                            Sensitive Personal Data is a subset of Personal Data
                            and is generally defined as any information related
                            to racial/ethnic origin, political opinions,
                            religious beliefs, trade union membership, physical
                            or mental health, other medical information
                            including biometric and genetic data, or sexual life
                            or criminal allegations or convictions, precise
                            geolocation information, financial and bank account
                            numbers, or unique identifiers such as
                            government-issued social security numbers, driver's
                            license, and passport numbers. We do not collect
                            "Sensitive Personal Data", except if the
                            subscription is for a personal application or the
                            crew management system wherein vessel operators
                            maybe required to provide certain Personal Data,
                            such as employment details, regarding crew members
                            for their use.
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4" className=" mt-2 mb-2">
                      <Accordion.Header>
                        Information that you Store on the Web applications
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            &nbsp;Some of the Services that are made available
                            through the web applications allow users to store
                            certain data on the web applications. We will not
                            access, monitor or use the contents of any user data
                            stored on our servers, except in the following
                            circumstances: <br />
                            (i) the data is related to user identification and
                            access authorization; <br />
                            (ii) the data is to be used to assist you, provided
                            that you have consented to such use; <br />
                            (iii) we are under the good faith belief that we are
                            required to do so pursuant to law, regulation, legal
                            process, governmental demand or other legal
                            obligation; <br />
                            (iv) we are under a good faith belief there is a
                            violation of the
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6" className=" mt-2 mb-2">
                      <Accordion.Header>
                        Non-Identifiable Data and Cookies
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            When you interact with ORION through the web
                            applications, we receive and store certain
                            non-identifiable information. Such information,
                            which is collected passively using various
                            technologies, cannot presently be used to
                            specifically identify you. ORION may use such
                            information and pool it with other information to
                            track, for example, the total number of visitors to
                            our web applications, the number of visitors to each
                            page of our web applications, and the domain names
                            of our visitors' Internet service providers. It is
                            important to note that no Personal Data is available
                            or used in this process. In operating this web
                            applications, we may use a technology called
                            "cookies." A cookie is a piece of information that
                            the computer that hosts our web applications gives
                            to your browser when you access the web
                            applications. Our cookies help provide additional
                            functionality to the web applications and help us
                            analyze web applications usage more accurately. On
                            most web browsers, you will find a "help" section on
                            the toolbar. Please refer to this section for
                            information on how to receive notification when you
                            are receiving a new cookie and how to turn cookies
                            off. We recommend that you leave cookies turned on
                            because they allow you to take advantage of some of
                            our web application features.
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11" className=" mt-2 mb-2">
                      <Accordion.Header>
                        Our Disclosure of Your Personal Data
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            ORION considers your Personal Data to be a vital
                            part of our relationship with you and is not in the
                            business of selling your Personal Data. There are,
                            however, certain circumstances in which we may share
                            your Personal Data with certain third parties
                            without further notice to you, as set forth below
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className=" mt-2 mb-2">
                      <Accordion.Header>Business Transfers</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            Incase of transfer or sale of businesses or assets.
                            In the event of a corporate sale, merger,
                            reorganization, dissolution or similar event,
                            Personal Data may be part of the transferred assets.
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9" className=" mt-2 mb-2">
                      <Accordion.Header>
                        Agents, Consultants and Related Third Parties
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            ORION, like many businesses, sometimes hires other
                            companies to perform certain business-related
                            functions. Examples of such functions include
                            mailing information, maintaining servers and
                            processing payments. When we employ another company
                            to perform a function of this nature, we only
                            provide them with the information that they need to
                            perform their specific function.
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1" className=" mt-2 mb-2">
                      <Accordion.Header>Legal Requirements</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            ORION may disclose your Personal Data if required to
                            do so by law or in the good faith belief that such
                            action is necessary to <br />
                            (i) comply with a legal obligation,
                            <br /> (ii) protect and defend the rights or
                            property of ORION, <br />
                            (iii) act in urgent circumstances to protect the
                            personal safety of users of the web applications or
                            other third parties, or <br />
                            (iv) protect against legal liability.
                          </li>
                          <li>
                            We share Personal Data with others only as described
                            in this statement, or when we believe that the law
                            permits or requires it.
                          </li>
                          <li>
                            If at any point you believe the information we
                            process on you is incorrect, you may request to see
                            this information and even have it corrected or
                            deleted. If you wish to raise a complaint on how we
                            have handled your Personal Data, you can contact our
                            Data Protection Officer who will investigate the
                            matter.
                          </li>
                          <li>
                            If you are not satisfied with our response or
                            believe we are processing your Personal Data not in
                            accordance with the law, you can complain to the
                            relevant supervisory authorities. A list of National
                            Data Protection Authorities can be found at:
                            <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                              {" "}
                              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                            </a>
                          </li>
                          <li>
                            Contact information for our Information Security
                            Officer is set forth below.
                          </li>
                          <li>
                            ORION will only keep Personal Data for so long as it
                            is being used for the purposes for which we collect
                            it. After it is no longer being used, it will be
                            deleted or destroyed according to generally accepted
                            data destruction standards.
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5" className=" mt-2 mb-2">
                      <Accordion.Header>External Links</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            This web applications may contain links to other web
                            applications operated by third parties. Before it
                            engages with third parties, ORION requires that the
                            third parties maintain security measures, that
                            comply with applicable privacy and data protection
                            laws, including the GDPR and agree to the
                            Confidentiality Agreement on this website.
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7" className=" mt-2 mb-2">
                      <Accordion.Header>
                        Changes to ORION's Privacy Policy
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            This web applications and our business may change
                            from time to time. As a result, at times it may be
                            necessary for ORION to make changes to this&nbsp;
                            <strong>Privacy Policy</strong>. ORION reserves the
                            right to update or modify this&nbsp;
                            <strong>Privacy Policy</strong>&nbsp;at any time and
                            from time to time without prior notice. Please
                            review this&nbsp;<strong>Privacy Policy</strong>
                            &nbsp;periodically, and especially before you
                            provide any Personal Data. This&nbsp;
                            <strong>Privacy Policy</strong>&nbsp;was last
                            updated on the date indicated below. Your continued
                            use of the web applications after any changes or
                            revisions to this&nbsp;
                            <strong>Privacy Policy</strong>&nbsp;shall indicate
                            your agreement with the terms of such revised&nbsp;
                            <strong>Privacy Policy</strong>.
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10" className=" mt-2 mb-2">
                      <Accordion.Header>Security</Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            ORION has a data protection regime in place to
                            oversee the affective and secure processing of
                            Personal Data. ORION takes reasonable steps to
                            protect the Personal Data provided via the web
                            applications from loss, misuse, and unauthorized
                            access, disclosure, alteration, or destruction.
                            However, no Internet or e-mail transmission is ever
                            fully secure or error free. Therefore, you should
                            take special care in deciding what information you
                            send to us via e-mail. With respect to your payment
                            information, please note that ORION works to protect
                            the security of all your information during
                            transmission over the Internet by using Transport
                            Security Layer(TLS) to encrypt your credit card
                            number and personal data. ORION will use your credit
                            card information only to process your payment and
                            will not retain or otherwise store your credit card
                            information after the processing of your payment.
                          </li>
                          <li>
                            We take appropriate steps to ensure that Personal
                            Data is processed, secured, and transferred
                            according to applicable law. The areas in which any
                            recipients are located will vary from time to time,
                            but may include the United States, Europe, Canada,
                            Asia, Australia, India and other countries where
                            ORION has a presence or uses contractors. For a list
                            of ORION offices, see our website.
                          </li>
                          <li>
                            When we transfer Personal Data from the European
                            Economic area to other countries in which applicable
                            laws do not offer the same level of data privacy
                            protection as in your home country, we take measures
                            to provide an appropriate level of data privacy
                            protection by use of the Confidentiality Agreement
                            on our website. In other words, your rights and
                            protections remain with your data.
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Row>
                </Accordion>
             
              <h5 className="mt-5 mb-5 fs-17">
                <strong className="fs-17">Contacting Us</strong>
                <strong>:</strong>
                &nbsp;If you have any questions about anything in this&nbsp;
                <strong>Privacy Policy</strong>, or about the collection of
                Personal Information through this web applications, please
                &nbsp;
                <strong>
                  Contact our Information Security Officer at &nbsp;
                </strong>
                <strong>dpo@orionmarineconcepts.com</strong>.
              </h5>
              <table id="signature" className="mb-5">
                <tbody>
                  <tr>
                    <td>
                      <img
                        alt="Signature"
                        src={signatureM}
                        style={{ maxWidth: "100%" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>
                        <h4>Signing Authority</h4>
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Capt. Mohit Sabharwal
                      <br />
                      Director
                      <br />
                      Orion Group
                      <br />
                      01 January 2022 &nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col xs={12} md={2}></Col>
          </Row>
        </Container>
        <Container fluid style={{ padding: "0" }} className=" text-left">
          <Row style={{ margin: "0", padding: "0" }}>
            <Row
              className=" pt-5 pb-5 text-center"
              style={{
                backgroundImage: "url(" + CrossPattern + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "0",
                margin: "0",
              }}
            >
              <Col xs={12} md={8}>
                <h1
                  className="BiEmpowering"
                  style={{ color: "#ffffff", paddingTop: "3%" }}
                >
                  Ready to Sail?{" "}
                </h1>
                <h1
                  className="BiEmpoweringBottomText"
                  style={{ color: "#ffffff", paddingTop: "3%" }}
                >
                  Set sail on a journey that redefines efficiency and ushers in
                  a new era in maritime management. Join the NAU 2.1 wave today.{" "}
                </h1>
              </Col>
              <Col xs={12} md={4}>
                <Button
                  variant="light"
                  className="m-t-90 p-l-50 p-r-50 fs-25 p-t-10 p-b-10 btn btn-light"
                >
                  <Link to="/ScheduleDemo" style={{ fontSize: "1.4rem" }}>
                    Embark Now
                  </Link>
                </Button>
              </Col>
            </Row>
          </Row>
        </Container>
      </Row>
    </>
  );
}
